<template>
  <div
    class="w-full h-full p-4 max-w-4xl sm:p-6 lg:p-12 flex flex-col justify-center backdrop-blur overlay"
  >
    <div class="mb-6 sm:mb-8 p-2">
      <h1 class="text-2xl sm:text-3xl font-bold mb-2">
        OpenBomber
      </h1>
      <p class="mb-4">
        Мощный смс бомбер — незаменимый инструмент для розыгрышей,
        который может послать тысячи СМС на указанный номер.
      </p>
      <UButton
        size="md"
        variant="solid"
        label="Попробовать бесплатно"
        to="/login"
        color="gray"
      />
    </div>
    <div class="">
      <div class="grid grid-cols-1 sm:grid-cols-2 sm:gap-6">
        <ULandingCard
          icon="i-heroicons-bolt"
          title="Работает на все 100%"
          description="Сервис умеет отправлять сообщения и звонки на все
                    русские номера."
          color="primary"
          class="mb-6"
        />
        <ULandingCard
          icon="i-heroicons-check-circle"
          title="Удобство в использовании"
          description="Используйте OpenBomber онлайн с любого удобного
                    устройства."
          color="primary"
          class="mb-6"
        />
      </div>

      <ULandingCard
        icon="i-heroicons-globe-alt"
        title="Безграничное использование"
        description="Используйте сервис без ограничений бесплатно или с
                подпиской, общее количество задач никак не ограничено."
        color="primary"
        class="mb-6"
      />
    </div>

    <div class="telegram-link flex items-center justify-center gap-2 mb-4">
      <img src="/tg.svg" alt="telegram" class="w-8 h-8">
      <ULink class="w-auto">
        Подпишись на наш телеграм канал
      </ULink>
    </div>
  </div>
</template>
