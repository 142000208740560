<script setup lang="ts">
useSeoMeta({
  title: 'OpenBomber - Лучший смс бомбер.',
})

definePageMeta({
  layout: 'no-footer',
})
</script>

<template>
  <div
    class="flex flex-col w-full lg:flex-row items-center justify-center h-full max-w-4xl"
  >
    <LeftColumn class="lg:pt-20" />
  </div>
</template>
